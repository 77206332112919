/* src/components/ManageWords/ManageWords.css */

.manage-words-container {
  margin: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Light background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar .MuiAutocomplete-root {
  width: 100%;
}

.search-bar .MuiOutlinedInput-root {
  width: 100%;
}

.search-bar .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  width: 100%;
}

.chip {
  margin: 5px;
}

.dialog-paper {
  background-color: rgba(255, 255, 255, 0.9); /* Light background for contrast */
  border-radius: 10px;
}

.dialog-content {
  padding: 20px;
}

.dialog-actions button {
  margin-right: 10px;
}

button {
  margin-top: 10px;
  background-color: rgba(93, 117, 153, 0.7); /* Blue button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: rgba(93, 117, 153, 0.9); /* Darker blue on hover */
}

.error-message {
  color: red;
  margin-top: 20px;
}
