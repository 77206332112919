/* src/components/About/About.css */

.about-container {
    min-height: 100vh;
    margin-top: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4; /* Light background color */
    border-radius: 1%;
  }
  
  .about-content {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 100px; /* Space from the top */
  }
  
  .about-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  .about-description {
    margin-bottom: 40px;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555;
  }
  
  .about-section-title {
    margin-top: 20px;
    font-size: 1.8rem;
    color: #333;
  }
  
  .about-content .MuiList-root {
    margin-bottom: 20px;
  }
  
  .about-content .MuiTypography-body1 {
    font-size: 1rem;
    color: #555;
  }
  