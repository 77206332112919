.footer-container {
    background-color: rgba(93, 117, 153, 1); /* Background color for the footer */
    color: white; /* Text color for the footer */
    padding: 50px;
    width: 100%;
    position: relative; /* Changed to relative */
    bottom: 0;
    text-align: left;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  
  .contact-info { 
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .social-media-links {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
  
  .social-link {
    color: rgb(103, 60, 122);
    margin-top: 5px;
  }
  
  .social-link:hover {
    text-decoration: underline;
    color:rgb(133, 49, 49, .5)
  }
  