.container {
  width: 90%;
  margin:  auto;
  background: white;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.header-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.nav-tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.nav-tabs li {
  margin: 0 10px;
}

.nav-tabs a {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #197fec;
  color: white;
  border-radius: 4px;
}

.nav-tabs a:hover {
  background-color: #0056b3;
}

.search-bar {
  margin-bottom: 20px;
  width: 100%;
}

table.activities-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table.activities-table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button.btn-secondary {
  background-color: #6c757d;
  color: white;
}

button.btn-danger {
  background-color: #dc3545;
  color: white;
}

button:hover {
  opacity: 0.8;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #007bff;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #f1f1f1;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border: none;
}

/* New styles for chips */
.chip-blue {
  background-color: #1976d2;
  color: white;
  margin: 5px;
}

.chip-purple {
  background-color: #9c27b0;
  color: white;
  margin: 5px;
}

.chip-green {
  background-color: #4caf50;
  color: white;
  margin: 5px;
}

/* Styles for dialog and form */
.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add more space between the fields */
}

.dialog-content .MuiTextField-root {
  margin-bottom: 16px;
}

.dialog-actions {
  justify-content: space-between;
}

/* Style for Add Activity button to make the dialog bigger */
.dialog-paper {
  width: 80%;
  max-width: 800px;
}