
/* src/components/Card/Card.css */


.card {
  background-color: white;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 300px; 
  display: flex;
  flex-direction: column;
}

.card-inner {
  position: relative;
  height: 300px; /* Adjust height as necessary */
}

.card-inner {
  position: relative ;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 25px;
}

.card-front {
  z-index: 2;
  transform: rotateY(0deg);
}

.card-back {
  background-color: white;
  transform: rotateY(180deg);
  overflow-y: auto;
}

.card-image img {
  width: 100%;
  height: 60%; /* Adjust height as necessary */
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.card-content {
  padding: 20px;
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--blue);
}

.card-subtitle {
  font-size: 16px;
  line-height: 1.5;
  color: var(--blue);
  overflow: hidden; /* Prevent long text from overflowing */
  text-overflow: ellipsis; /* Add ellipsis to long text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card-name {
  font-size: 18px;
  line-height: 1.5;
  padding-top: 20px;
  color: var(--blue);
}
