/* src/components/Search/Search.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  position: relative;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../data/vault7.webp'); /* Update with the correct path */
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  opacity: 0.7; /* Adjust the opacity to make the image lighter */
  z-index: -1; /* Ensure it stays behind the content */
}

.search-container {
  width: 1200px;
  margin:  auto;
  background: rgb(255, 255, 255);
  background: rgba(8, 54, 23, 0);
  padding: 20px; /* Adjusted padding to be more consistent */
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure it positions correctly within the viewport */
  padding-top: 60px; /* Add padding to ensure the top isn't cut off */
  height: 60vh;
  overflow-y: scroll;
}

.back-button {
  position: absolute;
  top: -10px;
  left: -540px;
  background-color: rgb(93, 117, 153);
  color: white;
}

.search-container h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.089);
}


.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-box {
  margin-bottom: 20px;
  width: 100%;
}

.autocomplete-box .MuiAutocomplete-root {
  width: 100%;
}

.autocomplete-box .MuiOutlinedInput-root {
  padding-right: 0;
}

.autocomplete-box .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 8px 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: white;
}

.autocomplete-box .MuiAutocomplete-clearIndicator {
  display: none;
}

.autocomplete-box .MuiAutocomplete-endAdornment {
  position: absolute;
  right: 10px;
  top: 34%;
  transform: translateY(-50%);
}

.autocomplete-box .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  width: 30px;
  height: 30px;
  padding: 10px;
}

.autocomplete-box .MuiIconButton-root {
  padding: 8px;
}

.autocomplete-box .MuiSvgIcon-root {
  font-size: 18px;
}

.search-container button {
  padding: 12px 24px;
  background-color: rgb(93, 117, 153);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: rgba(159, 74, 150, 0.9);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  gap: 25px;
  padding: 20px;
  padding-top:60px;
}

.chip-delete-icon {
  font-size: 16px;
  line-height: 16px;
  color: white;
  cursor: pointer;
}

.activity-card {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
}

.activity-card h6 {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.activity-card h5 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.activity-card h6 {
  margin-bottom: 10px;
  font-size: 20px;
}

.activity-card p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.5;
}

.activity-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.chip {
  margin: 5px 5px 5px 0;
}

.chip-target {
  background-color: rgba(245,247,249,255) !important;
  color: rgb(93, 117, 153) !important;
}

.chip-target:hover {
  background-color: rgb(171, 181, 196) !important;
  color: white !important;
}

.chip-target:focus,
.chip-target:active {
  background-color: rgb(90, 109, 137) !important;
  color: white !important; /* Ensure text color is white */
  opacity: 1;
}

.chip-category {
  background-color: rgb(247, 237, 237) !important;
  color: rgb(239, 103, 103) !important;
}

.chip-materials {
  background-color: rgb(244, 233, 243) !important;
  color: rgb(200, 125, 178) !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Ensure grid items are at least 250px wide */
  gap: 25px;
  padding: 20px;
  justify-content: center; /* Center the grid items */
}


@media (max-width: 600px) {
  .search-container {
    width: 100%;
    padding: 10px;
  }

  .search-container input,
  .search-container button {
    width: 100%;
    margin-bottom: 10px;
  }

  .activity-image {
    max-width: 100%;
  }
}