/* src/components/ActivityDetails/ActivityDetails.css */

.details-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.activity-card {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
}

.activity-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

.back-button {
  position: absolute;
  top: -10px;
  left: -540px;
  background-color: rgb(93, 117, 153) !important;
  color: white;
}

.grid-item h6 {
  font-size: 18px;
  margin-bottom: 10px;
}

.chip {
  margin: 5px 5px 5px 0;
}

.chip-target {
  background-color: rgba(245,247,249,255) !important;
  color: rgb(93, 117, 153) !important;
}

.chip-target:hover {
  background-color: rgb(171, 181, 196) !important;
  color: white !important;
}

.chip-target:focus,
.chip-target:active {
  background-color: rgb(90, 109, 137) !important;
  color: white !important;
  opacity: 1;
}

.chip-category {
  background-color: rgb(247, 237, 237) !important;
  color: rgb(239, 103, 103) !important;
}

.chip-materials {
  background-color: rgb(244, 233, 243) !important;
  color: rgb(200, 125, 178) !important;
}

.sentence-list {
  list-style: none;
  padding-left: 0;
}

.sentence-list li {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .details-container {
    padding: 10px;
  }

  .activity-image {
    max-width: 100%;
  }
}
