.app-bar {
  background-color: rgba(093, 117, 153, 1) !important; /* Use a darker shade of blue for better contrast */
  box-shadow: none;
  width: 100%;
  z-index: 1201; /* Ensure it is above other content */
  padding: 0 10px;
  flex-direction: column; /* Ensures vertical alignment for the search bar */
}

.app-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure proper spacing */
  width: 100%;
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.search-bar-container {
  flex-grow: 1;
  margin-left: 20px;
}

.search-bar {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: none;
  background-color: white;
  border-radius: 10px;
}

.search-bar input {
  padding: 10px;
  font-size: 1em;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.logo-image {
  height: 50px;
}

.tabs .MuiTab-root {
  padding: 5px 5px;
  margin: 0px 5px;
  border: 0px 5px;
  border-radius: 10px;
  background-color: rgba(093, 117, 153, 0.7); /* Blue (Paul Tol's Muted Pallete) */
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs .MuiTab-root:hover {
  background-color: rgba(159, 074, 150, 0.9); /* Pink (Paul Tol's Muted Pallete) */
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto; /* Adjust to fit the content */
  margin: 5px; /* Add some spacing between search icon and logo */
  padding: 5px;
  border-radius: 50%;
  background-color: rgb(093, 117, 153);/* blue */
  transition: background-color 0.3s;
}

.search-icon-container:hover {
  background-color: rgb(159, 074, 150); /* Lighter orange for hover */
}

.search-icon-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.search-bar-home .MuiOutlinedInput-notchedOutline {
  border: none; /* Remove the notched outline */

}

/* Add the following CSS to hide the clear (X) button */
.MuiAutocomplete-endAdornment {
  display: none;
}

.google-login {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.google-login button {
  background-color: white;
  color: rgba(093, 117, 153, 1);
  border: 1px solid rgba(093, 117, 153, 1);
  border-radius: 5px;
  padding: 5px 15px;
}

.google-login button:hover {
  background-color: rgba(093, 117, 153, 0.1);
}
