.App {
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  background-image: url('data/merge.jpg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  font-size: 1em;
  width: 200px;
  border: 2px solid #ddd;
  border-radius: 5px 0 0 5px;
}

.search-bar button {
  padding: 10px;
  font-size: 1em;
  background-color: #333;
  color: white;
  border: 2px solid #333;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
}

h2 {
  font-size: 1.5em;
  margin: 0;
}
