html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Center the content vertically */
  min-height: 100vh;
  width: 100%; /* Ensure the container spans the full width */
  height: auto;
  left: 0;  
  top: 0;
  background-image: url('../../data/vault6.png'); /* Update with the correct path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #ffffff;
  text-align: center;
  z-index: -0.5;
}

.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust the alpha value for transparency */
  z-index: 2; /* Ensure the overlay is above the background image */
}

.content-container {
  margin-top: 750px; /* Reset margin top to center title */
  width: 100%;
  padding: 0px;
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  min-height: calc(100vh-60px); /* Adjust based on header/footer height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensure the content container takes the full height */
}

.centered-title {
  font-size: 4rem; /* Adjust the size as needed */
  font-weight: bold;
  color: white; /* Set text color to white */
  z-index: 2; /* Ensure the text is above the overlay */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: add shadow for better visibility */
  margin-bottom: 20px; /* Space between title and the next content */
}

.cards-container {
  margin-top: 250px;
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  align-items: center; /* Center the cards vertically */
  padding: 20px;
  width: 100%; /* Set to 100% to ensure the full width */
  height: auto;
  flex-wrap: wrap; /* Allow the cards to wrap if necessary */
  z-index: 0; /* Ensure content is above the overlay */
  background-image: inherit;
  background: rgba(255, 255, 255, 0);
}

.cards-grid {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  align-items: center; /* Center the cards vertically */
}

.card {
  background-color: rgba(93, 117, 153, 1);
  margin: 10px;
  flex: 1 1 calc(33.333% - 20px); /* Ensure the cards take up 1/3 of the available space */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 0;
}

.language-select {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.tss-button-container {
  z-index: 1000;
  position: fixed; 
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.tts-toggle-button {
z-index: 1001;
}

.language-select .MuiInputLabel-outlined {
  color: #333;
}

.language-select .MuiOutlinedInput-root {
  color: #333;
}

.language-select .MuiOutlinedInput-notchedOutline {
  border-color: #333;
}

.language-select .MuiSelect-icon {
  color: #333;
}

.language-select .MuiMenuItem-root {
  color: #333;
}
