body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;
}

.chatbot-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chatbot-icon {
  width: 60px;
  height: 60px;
  background-color: rgb(127, 115, 181);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
}

.chat-bubble-icon {
  width: 24px;
  height: 20px;
  background-color: white;
  position: relative;
  border-radius: 12px;
}

.chat-bubble-icon::after {
  content: '';
  position: absolute;
  bottom: -9px;
  left: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: white;}

.chatbot-container {
  display: none;
  width: 350px; /* Reduced width */
  height: 500px; /* Reduced height */
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  margin-bottom: 55px;
  margin-right: 30px;
}

.chatbot-container.open {
  display: flex;
}

.chatbot-header {
  background-color: rgb(127, 115, 181);
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chatbot-toggle {
  cursor: pointer;
}

.chatbot-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatbot-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.chatbot-message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  max-width: 80%;
}

.chatbot-message img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.chatbot-message.user {
  align-self: flex-end;
  justify-content: flex-end;
  text-align: left;
  margin-left: 60px;
}

.chatbot-message.bot {
  align-self: flex-start;
  text-align: left;
}

.chatbot-message .chatbot-message-content {
  padding: 8px 20px;
  border-radius: 18px;
  background-color: #f1f1f1;
  word-break: break-word;
  max-width: 70%;
  font-size: 15px; 
}

.chatbot-message.user .chatbot-message-content {
  background-color: rgb(127, 115, 181);
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

.chatbot-message.bot .chatbot-message-content {
  background-color: #e5e5ea;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

.chatbot-message.system {
  align-self: center;
  background: none;
  color: #888;
  font-size: 12px; /* Reduced font size */
  padding: 5px;
  margin: 10px 0;
  border: none;
  border-radius: 0;
}

.chatbot-input-container {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  position: sticky;
  bottom: 0;
}

.chatbot-input-container textarea {
  flex: 1;
  padding: 8px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 20px;
  resize: none;
  font-size: 15px; /* Reduced font size */
  font-family: Arial, sans-serif;
}
.chatbot-input-container button {
  width: 40px; 
  padding: 10px;
  background-color: rgb(127, 115, 181);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbot-message-dalle img {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 0;
}

.generated-image {
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 0;
}


.typing-indicator {
  display: flex;
  gap: 5px;
}

.typing-indicator span {
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
  animation: blink 1.2s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
